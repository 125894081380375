// **** import modules ****
import $ from "jquery";

// local modules
import app, { csl, u_ } from "@@assets/scripts/app";
import vuwer from "@@assets/scripts/vuwer";

vuwer.add("breadcrumbs", {
	selector: "#breadcrumbs",
	pageList: ".page-list",
	
	onReady($self) {
		if(!$self) {
			csl.log.grey("no breadcrumbs.");
			return false;
		}
		
		this.$pageList = $self.find(this.pageList);
		this.$breads = this.$pageList.children();
		
		// set "home" path
		this.$breads.eq(0).children("a").attr({
			href: `${app._relativePath}/`
		});
	},
	
	getBreads(selector) {
		if(!this.$pageList) {
			return false;
		}
		
		if( selector && u_.isString(selector) ) {
			return this.$pageList.children(selector);
		} else {
			return this.$breads;
		}
	},
	
	getLevel() {
		return !!this.$breads ? this.$breads.length : -1;
	},
	
	upLevel(length) {
		if(!this.$pageList) {
			return this;
		}
		
		u_.isInteger(length) || (length = 1);
		this.getBreads().slice(length * -1).remove();
		
		return this;
	},
	
	addBread(opt) {
		if(!this.$pageList) {
			return false;
		}
		
		u_.isObject(opt) || (opt = {});
		opt = Object.assign({
			text: "",
			className: "",
			href: "",
			target: "_self"
		}, opt);
		
		const $elm = $("<span/>").text(opt.text);
		const $newChild = $("<li/>").append(
			!opt.href ? $elm :  $("<a/>").attr({
				href: opt.href,
				target: opt.target
			}).append($elm)
		);
		
		if( opt.className && app.isString(opt.className) ) {
			$newChild.addClass(opt.className);
		}
		
		this.$pageList.append($newChild);
		
		// 選択肢情報の更新
		this.$breads = this.$pageList.children();
		
		return $newChild;
	}
})
