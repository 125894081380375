// **** import modules ****
import app, { u_ } from "@@assets/scripts/app";
import vuwer from "@@assets/scripts/vuwer";

vuwer.add("pageHeader", {
	selector: "#page_header",
	titleSeparator: app.config.titleSeparator || " // ",
	
	onReady($self) {
		if(!$self) {
			return false;
		}
		
		this.$title = $self.find(".title");
		const title = this.$title.text();
		!!title && this.setTitle(title);
	},
	
	setTitle(title) {
		this.pageLevel = app._relativePath.split("/").length;
		if( u_.pathInfo(location.pathname).filename.replace("index", "") ) {
			this.pageLevel++;
		}
		
		if( title && u_.isString(title) ) {
			this.title = title;
			
			if(this.pageLevel > 1) {
				// [document title]
				const arr = document.title.split(this.titleSeparator);
				if(arr.length < this.pageLevel) {
					arr.unshift(this.title);
					document.title = arr.join(this.titleSeparator);
				}
				
				// [breadcrumbs]
				const breadcrumbs = vuwer.get("breadcrumbs");
				breadcrumbs.onReady($self => {
					if(breadcrumbs.getLevel() < this.pageLevel) {
						breadcrumbs.addBread({
							text: this.title
						});
					}
				});
				
				this.onReady( $self => !!$self && $self.find(".title").text(this.title) );
			}
		}
	}
});
