// **** import modules ****
// local modules
import vuwer from "@@assets/scripts/vuwer";


// **** process ****
vuwer.add("pageMain", {
	selector: "#page_main",
	onReady($self) {
		// ...
	}
});

