// **** import modules ****
import $ from "jquery";

// local modules
import app, { u_ } from "@@assets/scripts/app";
import vuwer from "@@assets/scripts/vuwer";
import loadGoogleCSE from "@@assets/scripts/loadGoogleCSE";


// **** process ****
vuwer.add("siteHeader", {
	selector: "#site_header",
	
	onReady($self) {
		if(!$self) {
			return false;
		}
		
		$self.find("a").each((i, elm) => {
			if(elm.href === app.URL) {
				$(elm).addClass("is-current");
			}
		});
		
		const position = $self.css("position");
		if("absolute_fixed".indexOf(position) >= 0) {
			vuwer.get("body").smoothScrollBuffer += $self.outerHeight(true);
		}
		
		// [google search engine]
		if(	u_.isObject(app.config.google) &&
				u_.isObject(app.config.google.cse) &&
				app.config.google.cse.searchID
		) {
			this.loadGoogleCSE(app.config.google.cse.searchID);
		}
	},
	
	loadGoogleCSE(searchID) {
		if(!searchID) {
			return false;
		}
		
		loadGoogleCSE(searchID, {
			// 埋め込み先のID（未指定の場合はbody直下の先頭に生成されます）
			targetID: "gcse_target",
			// テキストボックスのクラス
			textboxClass: "gcse-input gcse-textbox",
			// 検索実行ボタンのクラス
			submitClass: "gcse-input gcse-submit hoverTarget",
			// 検索ボタンのテキスト（未指定の場合は"検索"）
			submitValue: "&#xe8b6;"
		});
	}
}, function() {
	this
	// [nav.siteHeader]
	.add("nav", {
		selector: "#site_nav",
		closeMenuByScroll: true,
		
		onReady($self) {
			if(!$self) {
				return false;
			}
			
			this.$switch = $("#nav_switch");
			this.$content = $self.children(".content");
			!this.$content.length && (delete this.$content);
			
			if(this.$switch.length) {
				this.$switch.on("change", e => {
					const isOpen = e.target.checked;
					this.$switch.parent().toggleClass("is-open", isOpen);
					
					if(this.$content) {
						const { innerHeight } = window;
						const siteHeaderHeight = vuwer.get("siteHeader").$self.outerHeight();
						this.$content.css({
							"max-height": isOpen ? innerHeight - siteHeaderHeight : ""
						});
					}
					
					if(this.closeMenuByScroll) {
						if(isOpen) {
							this._scrollTopOnOpen = vuwer.$window.scrollTop();
							vuwer.$window.on("scroll.closeMenu", e => this._closeMenuByScroll());
						} else {
							vuwer.$window.off(".closeMenu");
						}
						
					} else {
						vuwer.get("body").$self.css("overflow", isOpen ? "hidden" : "");
					}
				}).prop("checked", false).trigger("change", [true]);
			}
		},
		
		_closeMenuByScroll() {
			const abs = Math.abs(vuwer.$window.scrollTop() - this._scrollTopOnOpen);
			(abs > 50) && this.$switch.prop("checked", false).trigger("change", [true]);
		}
	});
});
