// **** import modules ****
import app , { Klass } from "@@assets/scripts/app";
import sendGaPageview from "./sendGaPageview";
import onChangeHistory from "./onChangeHistory";

// **** process ****
Object.assign(app, {
	sendGaPageview,
	onChangeHistory,
	
	// ** override ** (for Google Analytics)
	pushState(data, url, opt) {
		if( Klass(app.klass).prototype.pushState.call(app, data, url) ) {
			return this.onChangeHistory(url, opt);
		} else {
			return false;
		}
	},
	
	// ** override ** (for Google Analytics)
	replaceState(data, url, opt) {
		if( Klass(app.klass).prototype.replaceState.call(app, data, url) ) {
			return this.onChangeHistory(url, opt);
		} else {
			return false;
		}
	}
});
