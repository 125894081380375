import $ from "jquery";

/**
 * IEのbackspaceキーでhistory.back()する機能を切る
 */
function killhistoryBackOnBackspace4IE() {
	const BACKSPACE_KEY = 8;
	$(document).on("keydown", e => {
		if(e.keyCode === BACKSPACE_KEY) {
			const tagName = e.target.tagName.toLowerCase();
			const $elm = $(e.target);
			if( (
				["input", "textarea"].indexOf(tagName) < 0 ||
				$elm.prop("readonly") ||
				$elm.prop("disabled")
			) && !$elm.closest(".ql-editor").length ) {
				return false;
			}
		}
	});
}

export default killhistoryBackOnBackspace4IE;
