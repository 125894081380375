// **** import modules ****
// jquery
import $ from "jquery";

// pm modules
import { Klass, u_ } from "@pressmedia/webappbase";
import vuwer from "@pressmedia/vuwer";


// **** process ****
const positionTracker =  {
	_targets: [],
	
	tracking(vuw, trackingStart, trackingEnd, buffer, callback) {
		if( !( vuw instanceof Klass("Vuw") ) || (!trackingStart && !trackingEnd) ) {
			return false;
		}
		
		!this._targets.length && vuwer.onReady($window => {
			$window
			.on("scroll.pt", e => {
				vuwer.positionTracker.onChangeScrollTop( $window.scrollTop() );
			})
			.on("resize.pt", (e, isTrigger) => {
				const scrollTop = $window.scrollTop();
				!!vuwer._resizePtTimer && clearTimeout(vuwer._resizePtTimer);
				vuwer._resizePtTimer = setTimeout(() => {
					if( scrollTop === $window.scrollTop() ) {
						vuwer.positionTracker.onWindowResize();
					}
				}, !!isTrigger ? 0 : 100);
			});
		});
		
		vuw._pt = {
			vuw,
			callback,
			$elm: $("<div/>").addClass("position-tracker"),
			attachable: true,
			check(y) {
				if( u_.isNumber(y) ) {
					const isFixed = (
						!u_.isNumber(this.fixStart) || y >= this.fixStart
					) && (
						!u_.isNumber(this.fixEnd) || y <= this.fixEnd
					);
					
					if(isFixed !== this.isFixed) {
						this.isFixed = isFixed;
						this.$elm.css("height", (isFixed && this.attachable) ? this.height : "");
						this.vuw.$self.toggleClass("is-fixed", isFixed);
						
						if( u_.isFunction(this.callback) ) {
							return this.callback.call(this.vuw, isFixed, this.buffer);
						}
					}
				}
			}
		};
		
		vuw.onReady($self => {
			vuw._pt.$elm.insertBefore( $self.addClass("position-tracking-target") );
		});
		
		if( u_.isNumber(buffer) ) {
			vuw._pt.buffer = buffer;
		} else {
			vuw._pt.buffer = 0;
			u_.isFunction(buffer) && (vuw._pt.calcBuffer = buffer);
		}
		
		!!trackingStart && (vuw._pt.fixStart = 0);
		!!trackingEnd && (vuw._pt.fixEnd = 0);
		
		this._targets.push( vuw.getAddress() );
		return this;
	},
	
	_updPtFixPosition() {
		const h = vuwer.$window.height();
		
		this._targets.forEach(vuwAddress => {
			const vuw = vuwer.get(vuwAddress);
			const top = !!vuw._pt.$elm.length ? vuw._pt.$elm.offset().top : false;
			
			if(!vuw || top === false) {
				return true;
			}
			
			const currentHeight = vuw._pt.height;
			vuw._pt.height = vuw.$self.outerHeight(true);
			
			if( vuw && vuw.isReady && u_.isNumber( vuw._pt.fixStart ) ) {
				vuw._pt.fixStart = top - vuw._pt.buffer;
			}
			if( vuw && vuw.isReady && u_.isNumber( vuw._pt.fixEnd ) ) {
				vuw._pt.fixEnd = top + vuw._pt.height - vuw._pt.buffer - h;
			}
			
			if(vuw._pt.isFixed) {
				if(vuw._pt.attachable && vuw._pt.height !== currentHeight) {
					vuw._pt.$elm.css("height", vuw._pt.height);
				}
			} else {
				vuw._pt.attachable = (["absolute", "fixed"].indexOf( vuw.$self.css("position") ) < 0);
			}
		});
		
		return this;
	},
	
	onWindowResize() {
		if(!this._targets.length) {
			return false;
		}
		
		this._targets.forEach(vuwAddress => {
			const vuw = vuwer.get(vuwAddress);
			if( vuw && u_.isFunction(vuw._pt.calcBuffer) ) {
				vuw._pt.buffer = vuw._pt.calcBuffer.call(vuw);
				u_.isNumber(vuw._pt.buffer) || (vuw._pt.buffer = 0);
			}
		});
		
		return this
		._updPtFixPosition()
		.onChangeScrollTop( vuwer.$window.scrollTop() );
	},
	
	onChangeScrollTop(y) {
		if(!this._targets.length) {
			return false;
		}
		
		this._targets.forEach(vuwAddress => {
			const vuw = vuwer.get(vuwAddress);
			(vuw && vuw.isReady) && vuw._pt.check(y);
		});
		
		return this;
	}
};

export default positionTracker;
