// **** import modules ****
// jQuery
import $ from "jquery";

// moment
import * as moment from "moment";
moment.locale("ja");

// WebAppBase
import app, { Klass, csl, u_ } from "./app";
import "./history";
import killhistoryBackOnBackspace4IE from "./history/killhistoryBackOnBackspace4IE";
import "@pressmedia/webappbase-plugin-popup";
/**********************************/
import "@pressmedia/webappbase-plugin-exec";
/**** ↑ no cms **** use cms ↓ ****/
// import "@pressmedia/tyrellmodule";
/**********************************/

// components
import vuwer from "./vuwer";
import "@@components/body";
import "@@components/siteHeader";
import "@@components/siteFooter";
import "@@cmscomponents/components/copyright";
import "@@cmscomponents/components/pageLoader";
import "@@components/breadcrumbs";
import "@@components/pageHeader";
import "@@components/pageMain";
import "@@components/scrollToPageTop";


// **** process ****
Object.assign(app, {
	// [on execute]
	async onExec(err, res) {
		// [Google Analytics] pageviewイベントを送信
		!!this.gaAutoSendPageview && this.sendGaPageview();
		
		// ポップアップ
		!!this.popup && this.popup.init(this);
		
		!this.now && ( this.now = moment() );
		
		if(err) {
			$(document.body).addClass("has-errors");
			csl.warn("onExec", "エラーのため処理を終了します", err);
			return false;
		}
		
		// IEのbackspaceキーでhistory.back()する機能を切る
		if(this.browser[0] === "ie") {
			killhistoryBackOnBackspace4IE();
		}
		
		// コンポーネントの準備
		const resVuwer = await vuwer.getReady();
		csl.log.blue("**** vuwer is ready. ****", resVuwer);
		
		// 初期状態をvuwerへ反映
		const state = Object.assign({}, app._GET);
		return vuwer.setState(state);
	},
	
	// [after execute]
	async afterExec(err) {
		// 画像部分の描画時間を考慮して、処理を遅らせる
		!err && await new Promise(resolve => setTimeout(resolve, 100));
		
		// フッタを最下部にフィックス
		vuwer.isReady && vuwer.fixBottom("siteFooter");
		
		const logMsg = `app is ready.${!err ? "" : " (but some errors has occured.)"}`;
		const logColor = !err ? "blue" : "red";
		csl.log[logColor](`**** ${logMsg} ****`);
		
		await new Promise(resolve => setTimeout(resolve, 0));
		
		vuwer.isReady && vuwer.$window
		.trigger("resize", [true])
		.trigger("scroll", [true]);
		
		csl.log.blue(
			`** page initialized: ${app.pageName || "{no pageName}"} **`
		);
	}
});

export default app;
export { Klass, csl, u_, vuwer };
