// **** import modules ****
import { csl } from "@@assets/scripts/app";

// **** process ****
function sendGaPageview() {
	const {
		gtag,
		google_tag_manager,
		ga
	} = window;
	
	if( !gtag && (google_tag_manager || !ga) ) {
		return false;
	}
	
	const pagePath = location.href.replace(
		new RegExp(`^${RegExp.escape(location.origin)}`),
		""
	);
	
	if(gtag) {
		gtag.sendPageview(pagePath);
		
	} else {
		ga("set", "page", pagePath);
		ga("send", "pageview");
	}
	
	csl.log.grey("**** send ga pageview.", pagePath);
}

export default sendGaPageview;
