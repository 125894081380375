// **** import modules ****
import $ from "jquery";

// local modules
import app, { csl } from "@@assets/scripts/app";
import vuwer from "@@assets/scripts/vuwer";


// **** process ****
vuwer
.add("siteFooter", {
	selector: "#site_footer",
	
	onReady($self) {
		if(!$self) {
			csl.log.grey("no siteFooter.");
			return false;
		}
		
		$self.find("a").each((i, elm) => {
			if(elm.href === app.URL) {
				$(elm).addClass("is-current");
			}
		});
	}
});
