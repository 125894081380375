// **** import modules ****
import vuwer from "@pressmedia/vuwer";
import _onReady from "@@cmscomponents/assets/vuwer/_onReady";
import _fixBottom from "@@cmscomponents/assets/vuwer/_fixBottom";

// local modules
import positionTracker from "./positionTracker";


// **** process ****
vuwer.setProp({
	..._onReady,
	..._fixBottom,
	positionTracker
});

// export to window
Object.assign(window, {
	vuwer
});

export default vuwer;
