import { u_ } from "@pressmedia/webappbase";

function _getOption(...args) {
	const len = args.length;
	const ret = {};
	
	for(let i = 0; i < len; i++) {
		const arg = args[i];
		for(const item in arg) {
			if( u_.hasProperty(arg, item) ) {
				ret[item] = arg[item];
			}
		}
	}
	
	return ret;
}

function loadGoogleCSE(searchEngineID, option) {
	// option指定項目の初期値
	const	def = {
		lang: "ja",
		targetID: "",
		formID: "cse_search_box",
		textboxClass: "",
		submitClass: "",
		submitValue: "検索"
	};
	
	(typeof option === "object") || (option = {});
	
	// optionを初期値とマージ
	option = _getOption(def, option);
	
	const nodes = {};
	nodes.wrap = !!option.targetID ? document.getElementById(option.targetID) : false;
	
	// option.targetIDで指定された要素が存在しない場合、body直下の先頭に生成する
	if(!nodes.wrap) {
		nodes.wrap = document.createElement("div");
		nodes.wrap.className = "google-cse";
		document.body.insertBefore(nodes.wrap, document.body.childNodes[0]);
	}
	
	// create form
	nodes.form = document.createElement("form");
	nodes.form.id = option.formID;
	
	const protocol = document.location.protocol === "https:" ? "https:" : "http:";
	nodes.form.action = protocol + "//cse.google.com/cse";
	nodes.form.target = "_blank";
	
	// append `cx`
	nodes.cx = document.createElement("input");
	nodes.cx.name = "cx";
	nodes.cx.type = "hidden";
	nodes.cx.value = searchEngineID;
	nodes.form.appendChild(nodes.cx);
	
	// append `ie`
	nodes.ie = document.createElement("input");
	nodes.ie.name = "ie";
	nodes.ie.type = "hidden";
	nodes.ie.value = "UTF-8";
	nodes.form.appendChild(nodes.ie);
	
	// append `hl` (if not "ja")
	nodes.hl = document.createElement("input");
	nodes.hl.name = "hl";
	nodes.hl.type = "hidden";
	nodes.hl.value = option.lang;
	nodes.form.appendChild(nodes.hl);
	
	// append `q`
	nodes.q = document.createElement("input");
	nodes.q.name = "q";
	nodes.q.type = "text";
	!!option.textboxClass && (nodes.q.className = option.textboxClass);
	nodes.form.appendChild(nodes.q);
	
	// append `sa`
	nodes.sa = document.createElement("input");
	nodes.sa.name = "sa";
	nodes.sa.type = "submit";
	!!option.submitClass && (nodes.sa.className = option.submitClass);
	if(option.submitValue) {
		// parse HTML String
		const elm = document.createElement("div");
		elm.innerHTML = option.submitValue;
		nodes.sa.value = elm.innerHTML;
	}
	nodes.form.appendChild(nodes.sa);
	
	// form appendTo wrap
	nodes.wrap.appendChild( nodes.form );
	
	const elmScript = document.createElement("script");
	const gcseUri = `${protocol}//www.google.com/coop/cse/brand`;
	elmScript.type = "text/javascript";
	elmScript.src = `${gcseUri}?form=${option.formID}&lang=${option.lang}`;
	nodes.wrap.appendChild(elmScript);
	
	return nodes;
}

export default loadGoogleCSE;
