// **** import modules ****
import $ from "jquery";
import { u_ } from "@@assets/scripts/app";
import vuwer from "@@assets/scripts/vuwer";

// **** process ****
function onChangeHistory(url, opt) {
	if( u_.isObject(opt) ) {
		if( opt.title && u_.isString(opt.title) ) {
			document.title = opt.title;
		}
		
		if( opt.description && u_.isString(opt.description) ) {
			if(!vuwer.meta.$description.length) {
				vuwer.meta.$description = $("<meta/>").attr("name", "description").insertAfter(
					vuwer.$head.children("meta").eq(-1)
				);
			}
			
			vuwer.meta.$description.attr({
				content: u_.replaceHtmlTag(opt.description)
			});
		}
		
		if( opt.keywords && u_.isString(opt.keywords) ) {
			if(!vuwer.meta.$keywords.length) {
				vuwer.meta.$keywords = $("<meta/>").attr("name", "keywords").insertAfter(
					vuwer.$head.children("meta").eq(-1)
				);
			}
			
			vuwer.meta.$keywords.attr({
				content: opt.keywords
			});
		}
		
		if(opt.canonical) {
			opt._GET = Object.assign({}, this._GET);
			// ※有効なクエリパラメータを指定する場合は配列をopt.canonicalに入れておく
			Array.isArray(opt.canonical) &&  Object.keys(opt._GET).forEach(k => {
				if(opt.canonical.indexOf(k) < 0) {
					delete opt._GET[k];
				}
			});
			
			vuwer.meta.$canonical.attr( "href", this.URL + u_.createQueryString(opt._GET) );
		}
	}
	
	this.sendGaPageview();
	
	return this;
}

export default onChangeHistory;
